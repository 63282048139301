<!--
  作者：顾先生
-->
<template>
  <div id="PublicTop_container">
    <!--    头部-->
    <div class="topBox wapper">
      <!--      放图片-->
      <img src="@/assets/image/topimg.png" class="topBox_img">
      <!--      文字-->
      <ul class="topBox_ul">
        <li @click="HomePage()">首页</li>
        <li @click="ProductPage()">SSL产品介绍</li>
        <li @click="TechnologyPage()">技术支持</li>
        <li @click="AboutPage()">关于我们</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PublicTop',
  // 注册组件
  components: {},
  // 注册方法
  methods: {
    // 首页
    HomePage () {
      this.$router.push({
        path: '/'
      })
    },
    // SSL产品介绍
    ProductPage () {
      this.$router.push({
        path: '/ProductPage'
      })
    },
    // 技术支持
    TechnologyPage () {
      this.$router.push({
        path: '/TechnologyPage'
      })
    },
    // 关于我们
    AboutPage () {
      this.$router.push({
        path: '/AboutPage/v'
      })
    }
  },
  // 父子传值
  props: {},
  // 数据渲染
  data () {
    return {

    }
  },
  // 计算属性
  computed: {},
  // 监听器
  watch: {},
  // 页面初次解析完成
  created () {},
  // 页面内容渲染完成
  mounted () {}
}
</script>

<style scoped lang="less">
#PublicTop_container{
  // 头部
  .topBox{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 55px;
    // 图片
    .topBox_img{
      width: 99px;
      height: 38px;
    }
    // 文字
    .topBox_ul{
      display: flex;
      color: #333333;
    }
    // 文字间距
    .topBox_ul li{
      font-size: 16px;
      padding-right: 20px;
    }
    // 文字悬浮
    .topBox_ul li:hover{
      transition: all .3s;
      color: #127BE6;
      cursor: pointer;
    }
  }
}
</style>
