import { render, staticRenderFns } from "./TechnologyPage.vue?vue&type=template&id=4839a4f2&scoped=true&"
import script from "./TechnologyPage.vue?vue&type=script&lang=js&"
export * from "./TechnologyPage.vue?vue&type=script&lang=js&"
import style0 from "./TechnologyPage.vue?vue&type=style&index=0&id=4839a4f2&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4839a4f2",
  null
  
)

export default component.exports