<!--
  作者：顾先生
-->
<template>
  <div id="ContentPage_container">
    <!--    公共头部-->
    <publicTop></publicTop>
    <!--    图片背景-->
    <div class="bgcBox">
      <p class="bgcBox_p1">技术支持</p>
    </div>
    <!--    内容区域-->
    <div class="contentBox wapper" v-html="DemoText"></div>
    <!--    公共底部-->
    <publicBottom></publicBottom>
  </div>
</template>

<script>
import publicTop from '@/components/PublicTop.vue'
import publicBottom from '@/components/PublicBottom.vue'
import axios from 'axios'
import qs from 'qs'
export default {
  name: 'ContentPage',
  // 注册组件
  components: { publicTop, publicBottom },
  // 注册方法
  methods: {},
  // 父子传值
  props: {},
  // 数据渲染
  data () {
    return {
      DemoText: null
    }
  },
  // 计算属性
  computed: {},
  // 监听器
  watch: {},
  // 页面初次解析完成
  created () {
    const pList = qs.stringify({ name: this.$route.params.numNumber })
    axios.post('https://msg.zxpb.cn/zqx/sms.smswebtemp/temp', pList)
      // eslint-disable-next-line no-irregular-whitespace
      .then((res) => {
        this.DemoText = res.data
      })
      .catch(err => {
        console.log(err)
      })
  },
  // 页面内容渲染完成
  mounted () {}
}
</script>

<style scoped lang="less">
#ContentPage_container{
  // 图片背景
  .bgcBox{
    width: 100%;
    height: 150px;
    background: url("@/assets/image/jishuBgc.png") no-repeat;
    background-size: 100% 100%;
    .bgcBox_p1{
      text-align: center;
      line-height: 150px;
      font-size: 32px;
      color: #FFFFFF;
      font-weight: bold;
    }
  }
  // 内容区域
  .contentBox{
    margin-top: 28px;
    background-color: #FAFAFA;
  }
}
</style>
