<!--
  作者：顾先生
-->
<template>
  <div id="HomePage_container">
    <!--    公共头部-->
    <PublicTop></PublicTop>
    <!--    轮播图-->
    <div class="LunBox clearfix">
      <el-carousel indicator-position="outside" height="350px">
        <el-carousel-item v-for="(item,indedx) in imgList" :key="indedx">
          <img :src="item.idView" class="LunBoxImg">
        </el-carousel-item>
      </el-carousel>
      <div class="LunBox_font wapper">
        <p class="LunBox_font_p1">SSL证书</p>
        <p class="LunBox_font_p2">称为服务器SSL证书，是遵守SSL/TLS协议的一种数字证书,由全球信任</p>
        <p class="LunBox_font_p2">的证书颁发机构（CA）验证服务器身份后颁发将SSL证书安装在网站</p>
        <p class="LunBox_font_p2">服务器上，可实现HTTPS加密传输和服务器身份认证双重功能。</p>
      </div>
    </div>
    <!--    ssl证书应用场景-->
    <div class="CJBox wapper">
      <p class="CJBox_p1">ssl证书应用场景</p>
      <div class="CJBox_img wapper">
        <img src="../assets/image/middle1.png" class="CJBox_img1">
        <img src="../assets/image/middle2.png" class="CJBox_img1">
        <img src="../assets/image/middle3.png" class="CJBox_img1">
        <img src="../assets/image/middle4.png" class="CJBox_img1">
      </div>
    </div>
    <!--    ssl产品介绍-->
    <div class="CPBox">
      <p class="CPBox_p1">ssl产品介绍</p>
      <div class="CPBox_span">
       <span class="CPBox_span1" v-for="(item,index) in list" :class="{CPBox_active : active == index}" @click="change(index)" :key="index">{{ item }}</span>
      </div>
      <!--      产品价格图-->
      <div class="CPBox_Price1 wapper" v-show="active===0" @click="llb">
        <img src="../assets/image/SSL3.png" class="CPBox_Price1_img1">
        <img src="../assets/image/SSL1.png" class="CPBox_Price1_img1">
        <img src="../assets/image/SSL2.png" class="CPBox_Price1_img1">
      </div>
      <!--      产品价格图-->
      <div class="CPBox_Price1 wapper" v-show="active===1" @click="llb">
        <img src="../assets/image/SSL4.png" class="CPBox_Price1_img1">
        <img src="../assets/image/SSL5.png" class="CPBox_Price1_img1">
        <img src="../assets/image/SSL6.png" class="CPBox_Price1_img1">
      </div>
      <!--      产品价格图-->
      <div class="CPBox_Price2 wapper" v-show="active===2" @click="llb">
        <img src="../assets/image/SSL7.png" class="CPBox_Price1_img1">
        <img src="../assets/image/SSL8.png" class="CPBox_Price1_img1">
      </div>
    </div>
    <!--    SSL证书介绍-->
    <div class="certificateBox wapper">
      <p class="certificateBox_p1">ssl数字证书的作用</p>
      <div class="certificateimg_Box"></div>
      <div class="CJBox_img wapper">
        <img src="../assets/image/btom1.png" class="CJBox_img1">
        <img src="../assets/image/btom2.png" class="CJBox_img1">
        <img src="../assets/image/btom3.png" class="CJBox_img1">
      </div>

    </div>
    <!--    ssl数字证书的重要性-->
    <div class="importantBox">
      <p class="certificateBox_p1">ssl数字证书的重要性</p>
      <div class="CJBox_img wapper">
        <img src="../assets/image/shu1.png" class="CJBox_img1">
        <img src="../assets/image/shu2.png" class="CJBox_img1">
        <img src="../assets/image/shu3.png" class="CJBox_img1">
        <img src="../assets/image/shu4.png" class="CJBox_img1">
      </div>
    </div>
    <!--    底部的样式-->
    <div class="bottomBgc">
        <!--      文字-->
      <div class="bottomBox">
        <span class="bottomBox_span1">完善解决方案让数据更安全</span>
        <span class="bottomBox_span2" @click="dingzhi()">点击定制</span>
      </div>
    </div>
    <!--    公共底部-->
    <PublicBottom></PublicBottom>
  </div>
</template>

<script>
import PublicTop from '@/components/PublicTop.vue'
import PublicBottom from '@/components/PublicBottom.vue'
export default {
  name: 'HomePage',
  // 注册组件
  components: { PublicTop, PublicBottom },
  // 注册方法
  methods: {
    dingzhi () {
      const val = 'v'
      this.$router.push({
        path: `/AboutPage/${val}`
      })
    },
    change (index) {
      this.active = index
    },
    llb () {
      this.$router.push({
        path: '/ProductPage'
      })
    }
  },
  // 父子传值
  props: {},
  // 数据渲染
  data () {
    return {
      list: ['SSL单域名证书价格', 'SSL多域名证书价格', 'SSL通配符证书价格'],
      active: 0,
      imgList: [
        { id: 0, idView: require('@/assets/image/1.png') },
        { id: 1, idView: require('@/assets/image/2.png') },
        { id: 2, idView: require('@/assets/image/3.png') },
        { id: 2, idView: require('@/assets/image/4.png') }
      ]
    }
  },
  // 计算属性
  computed: {},
  // 监听器
  watch: {},
  // 页面初次解析完成
  created () {},
  // 页面内容渲染完成
  mounted () {}
}
</script>

<style scoped lang="less">
#HomePage_container{
  // 轮播图
  .LunBox{
    position: relative;
    width: 100%;
    height: 350px;
    background: url("@/assets/image/1.png") no-repeat;
    background-size: 100% 100%;
    .el-carousel__item h3 {
      color: #475669;
      font-size: 18px;
      opacity: 0.75;
      line-height: 300px;
      margin: 0;
    }
    .el-carousel__item:nth-child(2n) {
      background-color: #99a9bf;
    }
    .el-carousel__item:nth-child(2n+1) {
      background-color: #d3dce6;
    }
    .LunBoxImg{
      width: 100%;
      height: 350px;
    }
    .LunBox_font{
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 100px;
      z-index: 9999999999;
    }
    // 字体样式
    .LunBox_font_p1{
      margin-bottom: 20px;
      font-size: 31px;
      font-weight: bold;
      color: #FFFFFF;
      text-shadow: 0px 6px 4px rgba(0,0,0,0.27);
    }
    .LunBox_font_p2{
      text-align: justify;
      font-size: 18px;
      line-height: 32px;
      color: #FFFFFF;
      font-weight: 400;
    }
  }
  // ssl证书应用场景
  .CJBox{
    margin-top: 38px;
    .CJBox_p1{
      text-align: center;
      font-size: 25px;
      font-weight: bold;
      color: #333333;
    }
    .CJBox_img{
      display: flex;
      justify-content: space-between;
      margin-top: 38px;
      .CJBox_img1{
        display: block;
        width: 270px;
        transition: all .5s;
        &:hover{
          transform: translateY(-20px);
        }
      }
    }
  }
  // ssl产品介绍
  .CPBox{
    width: 100%;
    padding: 28px 0px;
    background-color: #F6F6F6;
    margin-top: 38px;
    .CPBox_p1{
      text-align: center;
      font-size: 25px;
      font-weight: bold;
      color: #333333;
    }
    // 证书价格文字
    .CPBox_span{
      display: flex;
      justify-content: space-between;
      margin: 25px auto 0px;
      width: 512px;
      font-size: 17px;
      .CPBox_span1{
        display: block;
        padding: 5px 0px;
        font-weight: bold;
        border-bottom: 1px solid #F6F6F6;
        transition: all .3s;
        &:hover{
          cursor: pointer;
        }
      }
      .CPBox_active{
        color: #127BE6;
        border-bottom: 1px solid #127BE6;
      }
    }
    // 产品价格图
    .CPBox_Price1{
      display: flex;
      justify-content: space-between;
      margin-top: 25px;
      .CPBox_Price1_img1{
        width: 340px;
        transition: all .8s;
        &:hover{
          cursor: pointer;
          transform: translateY(-10px);
          transform: scale(1.01);
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        }
      }
    }
    // 产品价格图
    .CPBox_Price2{
      display: flex;
      justify-content: space-evenly;
      margin-top: 25px;
      .CPBox_Price1_img1{
        width: 340px;
        transition: all .8s;
        &:hover{
          cursor: pointer;
          transform: translateY(-10px);
          transform: scale(1.01);
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        }
      }
    }
  }
  // SSL证书介绍
  .certificateBox{
    margin-top: 38px;
    // 顶部剧中文字
    .certificateBox_p1{
      text-align: center;
      font-size: 25px;
      font-weight: bold;
      color: #333333;
    }
    .CJBox_img{
      display: flex;
      justify-content: space-around;
      margin-top: 38px;
      .CJBox_img1{
        display: block;
        width: 270px;
        transition: all .8s;
        //&:hover{
        //  cursor: pointer;
        //  transform: translateY(-10px);
        //  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        //}
      }
    }
  }
  // SSL数字证书的重要性
  .importantBox{
    width: 100%;
    margin-top: 38px;
    padding: 28px 0px;
    background-color: #F6F6F6;
    // 顶部剧中文字
    .certificateBox_p1{
      text-align: center;
      font-size: 25px;
      font-weight: bold;
      color: #333333;
    }
    .CJBox_img{
      display: flex;
      justify-content: space-around;
      margin-top: 38px;
      .CJBox_img1{
        display: block;
        width: 270px;
        transition: all .5s;
        &:hover{
          transform: translateY(-20px) scale(1.02);
        }
      }
    }
  }
  // 底部的样式
  .bottomBgc{
    margin-top: 28px;
    width: 100%;
    height: 90px;
    background: url("@/assets/image/bgcimg.png") no-repeat;
    background-size: 100% 100%;
    // 文字
    .bottomBox{
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 420px;
      height: 90px;
      margin: 0 auto;
      .bottomBox_span1{
        font-size: 23px;
        line-height: 90px;
        font-weight: 400;
        color: #FFFFFF;
      }
      .bottomBox_span2{
        display: block;
        font-size: 14px;
        width: 90px;
        text-align: center;
        line-height: 38px;
        height: 38px;
        color: white;
        background: #F87B00;
        transition: all .5s;
        &:hover{
          cursor: pointer;
          background-color: #f83e00;
        }
      }
    }
  }
}
</style>
