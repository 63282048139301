<!--
  作者：顾先生
-->
<template>
  <div id="AboutPage_container">
    <!--    公共头部-->
    <publicTop></publicTop>
    <!--    图片背景-->
    <div class="bgcBox">
      <p class="bgcBox_p1">关于我们</p>
    </div>
    <!--    关于我们-->
    <div class="guanyuBox wapper">
      <p class="guanyuBox_p1">关于我们</p>
      <img src="../assets/image/guuanyu.png" class="guanyuBox_img1">
      <p class="guanyuBox_p2">保定梵天网络科技有限公司</p>
      <p class="guanyuBox_p3">
        <img src="../assets/image/phone.png" class="guanyuBox_icon1">&nbsp;<span class="guanyuBox_span2">400-8855-561</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<img src="../assets/image/phone2.png" class="guanyuBox_icon1">&nbsp;<span class="guanyuBox_span2">071000</span>
      </p>
      <p class="guanyuBox_p3">
        <img src="../assets/image/youxiang.png" class="guanyuBox_icon1">&nbsp;<span class="guanyuBox_span2">postmaster@rztrust.com</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<img src="../assets/image/address.png" class="guanyuBox_icon1">&nbsp;<span class="guanyuBox_span2">河北省保定市莲池区东金庄乡七一东路1588号未来金融港1号楼514室</span>
      </p>
    </div>
    <!--    免费咨询-->
    <div class="mianfeiZi" ref="mianfeiZi">
      <p class="mianfeiZi_p1">免费咨询</p>
      <div class="mianfeiZi_box wapper">
        <div class="mianfeiZi_box_left"></div>
        <div class="mianfeiZi_box_right">
          <p class="mianfeiZi_box_right_p1">官网网址：<input type="text" placeholder="请输入要验证的网址" class="mianfeiZi_box_right_input1" v-model="val1"></p>
          <p class="mianfeiZi_box_right_p1">联系人员：<input type="text" placeholder="请输入姓名" class="mianfeiZi_box_right_input1" v-model="val2"></p>
          <p class="mianfeiZi_box_right_p1">回访电话：<input type="number" placeholder="请输入回访电话" class="mianfeiZi_box_right_input1" v-model="val3"></p>
          <div class="mianfeiZi_Btn" @click="btn()">确认提交</div>
        </div>
      </div>
    </div>
    <!--    公共底部-->
    <publicBottom></publicBottom>
  </div>
</template>

<script>
import publicTop from '@/components/PublicTop.vue'
import publicBottom from '@/components/PublicBottom.vue'

export default {
  name: 'AboutPage',
  // 注册组件
  components: { publicBottom, publicTop },
  // 注册方法
  methods: {
    btn () {
      // eslint-disable-next-line no-empty
      if (this.val1.trim() === '') {
        this.$alert('网址不能为空', '', {
          confirmButtonText: '确定'
        })
        // eslint-disable-next-line no-empty
      } else if (this.val2.trim() === '') {
        this.$alert('姓名不能为空', '', {
          confirmButtonText: '确定'
        })
        // eslint-disable-next-line no-empty
      } else if (this.val3.trim() === '') {
        this.$alert('电话不能为空', '', {
          confirmButtonText: '确定'
        })
      } else {
        this.$alert('保存成功', '', {
          confirmButtonText: '确定'
        })
        this.val1 = ''
        this.val2 = ''
        this.val3 = ''
      }
    },
    scrollToTop () {
      this.$nextTick(() => {
        window.scrollTo(0, this.$refs.mianfeiZi.getBoundingClientRect().top)
      })
    }
  },
  // 父子传值
  props: {},
  // 数据渲染
  data () {
    return {
      val1: '',
      val2: '',
      val3: ''
    }
  },
  // 计算属性
  computed: {},
  // 监听器
  watch: {},
  // 页面初次解析完成
  created () {
  },
  // 页面内容渲染完成
  mounted () {
    if (this.$route.params.NumberS === 'LLB') {
      this.scrollToTop()
    }
  }
}
</script>

<style scoped lang="less">
#AboutPage_container{
  // 图片背景
  .bgcBox{
    width: 100%;
    height: 150px;
    background: url("@/assets/image/guanyu.png") no-repeat;
    background-size: 100% 100%;
    .bgcBox_p1{
      text-align: center;
      line-height: 150px;
      font-size: 32px;
      color: #FFFFFF;
      font-weight: bold;
    }
  }
  // 关于我们
  .guanyuBox{
    margin-top: 60px;
    margin-bottom: 100px;
    .guanyuBox_p1{
      text-align: center;
      font-size: 29px;
      font-weight: bold;
      color: #333333;
    }
    .guanyuBox_img1{
      margin-top: 20px;
      width: 100%;
    }
    .guanyuBox_p2{
      margin-top: 10px;
      font-size: 20px;
      font-weight: bold;
      color: #127BE6;
    }
    .guanyuBox_p3{
      margin-top: 10px;
      display: flex;
      align-items: center;
    }
  }
  // 免费咨询
  .mianfeiZi{
    margin-top: 30px;
    padding: 30px 0px;
    width: 100%;
    background-color: #F9F9F9;
    .mianfeiZi_p1{
      text-align: center;
      font-weight: bold;
      font-size: 28px;
      color: #333333;
    }
    .mianfeiZi_box{
      width: 1000px;
      margin-top: 50px;
      display: flex;
      justify-content: space-between;
      .mianfeiZi_box_left{
        width: 45%;
        height: 200px;
        background: url("@/assets/image/mianfei.png") no-repeat;
        background-size: 100% 100%;
      }
      .mianfeiZi_box_right{
        width: 45%;
        height: 200px;
      }
      .mianfeiZi_box_right_p1{
        margin-top: 12px;
        font-size: 20px;
        font-weight: 400;
        color: #666666;
      }
      .mianfeiZi_box_right_input1{
        text-align: center;
        width: 294px;
        height: 28px;
        background: #FFFFFF;
        border: 1px solid #127BE6;
        outline: none;
      }
      .mianfeiZi_Btn{
        margin: 20px auto 0px;
        width: 120px;
        height: 40px;
        line-height: 40px;
        font-size: 16px;
        color: white;
        text-align: center;
        background-color: #127BE6;
        transition: all .5s;
        &:hover{
          cursor: pointer;
          background-color: #1263e6;
        }
      }
    }
  }
}
</style>
