<!--
  作者：顾先生
-->
<template>
  <div id="TechnologyPage_container">
    <!--    公共头部-->
    <publicTop></publicTop>
    <!--    图片背景-->
    <div class="bgcBox">
      <p class="bgcBox_p1">技术支持</p>
    </div>
    <!--    功能栏-->
    <div class="functionBox">
      <div class="functionBoxBox wapper">
        <p class="functionBoxBox_p1" @click="handleScroll('one')">证书介绍</p>
        <p class="functionBoxBox_p1" @click="handleScroll('two')">部署指南</p>
        <p class="functionBoxBox_p1" @click="handleScroll('three')">常见问题</p>
      </div>
    </div>
    <!--    证书介绍-->
    <div class="box1" id="one">
      <p class="box1_p1">证书介绍</p>
      <div class="box1_zi wapper clearfix">
        <div class="box1_zi_Desc" v-for="(item,index) in contentList1" :key="index">
          <p class="box1_zi_Desc_p1">·&nbsp;&nbsp;{{ item }}</p>
          <p class="box1_zi_Desc_p2" @click="ChaKan(item)">查看</p>
        </div>
      </div>
    </div>
    <!--    部署-->
    <div class="box2" id="two">
      <p class="box1_p1">部署指南</p>
      <div class="box1_zi wapper clearfix">
        <div class="box1_zi_Desc" v-for="(item,index) in contentList2" :key="index">
          <p class="box1_zi_Desc_p1">·&nbsp;&nbsp;{{ item }}</p>
          <p class="box1_zi_Desc_p2" @click="ChaKan(item)">查看</p>
        </div>
      </div>
    </div>
    <!--    常见问题-->
    <div class="box3"  id="three">
      <p class="box1_p1">常见问题</p>
      <div class="box1_zi wapper clearfix">
        <div class="box1_zi_Desc" v-for="(item,index) in contentList3" :key="index">
          <p class="box1_zi_Desc_p1">·&nbsp;&nbsp;{{ item }}</p>
          <p class="box1_zi_Desc_p2" @click="ChaKan(item)">查看</p>
        </div>
      </div>
    </div>
    <!--    公共底部-->
    <publicBottom></publicBottom>
  </div>
</template>

<script>
import publicTop from '@/components/PublicTop.vue'
import axios from 'axios'
import publicBottom from '@/components/PublicBottom.vue'
// import qs from 'qs'
export default {
  name: 'TechnologyPage',
  // 注册组件
  components: { publicTop, publicBottom },
  // 注册方法
  methods: {
    handleScroll (id) {
      const element = document.getElementById(id)
      element.scrollIntoView() // 参数 false 代表 Bottom
    },
    // 请求数据
    getInfo1 () {
      axios.get('https://msg.zxpb.cn/zqx/sms.smswebtemp/biaoti?group=1')
        // eslint-disable-next-line no-irregular-whitespace
        .then(({ data }) => {
          this.contentList1 = data
        })
    },
    // 请求数据
    getInfo2 () {
      axios.get('https://msg.zxpb.cn/zqx/sms.smswebtemp/biaoti?group=2')
        // eslint-disable-next-line no-irregular-whitespace
        .then(({ data }) => {
          this.contentList2 = data
        })
    },
    // 请求数据
    getInfo3 () {
      axios.get('https://msg.zxpb.cn/zqx/sms.smswebtemp/biaoti?group=3')
        // eslint-disable-next-line no-irregular-whitespace
        .then(({ data }) => {
          this.contentList3 = data
        })
    },
    // 查看数据
    ChaKan (val) {
      this.$router.push({
        path: `/ContentPage/${val}`
      })
    }
  },
  // 父子传值
  props: {},
  // 数据渲染
  data () {
    return {
      contentList1: [],
      contentList2: [],
      contentList3: []
    }
  },
  // 计算属性
  computed: {},
  // 监听器
  watch: {},
  // 页面初次解析完成
  created () {
    this.getInfo1()
    this.getInfo2()
    this.getInfo3()
  },
  // 页面内容渲染完成
  mounted () {}
}
</script>

<style scoped lang="less">
#TechnologyPage_container{
  // 图片背景
  .bgcBox{
    width: 100%;
    height: 150px;
    background: url("@/assets/image/jishuBgc.png") no-repeat;
    background-size: 100% 100%;
    .bgcBox_p1{
      text-align: center;
      line-height: 150px;
      font-size: 32px;
      color: #FFFFFF;
      font-weight: bold;
    }
  }
  // 功能栏
  .functionBox{
    width: 100%;
    height: 60px;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px 0px rgba(125,125,125,0.27);
    .functionBoxBox{
      display: flex;
      height: 100%;
      .functionBoxBox_p1{
        font-size: 18px;
        width: 100px;
        height: 100%;
        line-height: 60px;
        text-align: center;
        font-weight: bold;
        color: #333333;
        transition: all .5s;
        &:hover{
          cursor: pointer;
          color: #127BE6;
        }
      }
    }
  }
  // 证书介绍
  .box1{
    margin-top: 10px;
    padding: 15px 0px;
    width: 100%;
    background-color: #FAFAFA;
    .box1_p1{
      font-size: 29px;
      font-weight: bold;
      color: #333333;
      text-align: center;
    }
    .box1_zi{
      margin-top: 30px;
      padding: 0px 15px;
      padding-bottom: 15px;
      background: #FFFFFF;
      box-shadow: 0px 0px 9px 2px rgba(185,185,185,0.27);
      .box1_zi_Desc{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
        width: 100%;
        height: 38px;
        background: #F9F9F9;
        .box1_zi_Desc_p1{
          margin-left: 20px;
          font-size: 16px;
          font-weight: bold;
          transition: all .5s;
          color: #333333;
          &:hover{
            cursor: pointer;
            color: #127BE6;
          }
        }
        .box1_zi_Desc_p2{
          margin-right: 20px;
          font-size: 16px;
          font-weight: bold;
          transition: all .5s;
          color: #333333;
          &:hover{
            cursor: pointer;
            color: #127BE6;
          }
        }
      }
    }
  }
  // 证书介绍
  .box2{
    margin-top: 50px;
    padding: 15px 0px;
    width: 100%;
    background-color: white;
    .box1_p1{
      font-size: 29px;
      font-weight: bold;
      color: #333333;
      text-align: center;
    }
    .box1_zi{
      margin-top: 30px;
      padding: 0px 15px;
      padding-bottom: 15px;
      background: #FFFFFF;
      box-shadow: 0px 0px 9px 2px rgba(185,185,185,0.27);
      .box1_zi_Desc{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
        width: 100%;
        height: 38px;
        background: #F9F9F9;
        .box1_zi_Desc_p1{
          margin-left: 20px;
          font-size: 16px;
          font-weight: bold;
          transition: all .5s;
          color: #333333;
          &:hover{
            cursor: pointer;
            color: #127BE6;
          }
        }
        .box1_zi_Desc_p2{
          margin-right: 20px;
          font-size: 16px;
          font-weight: bold;
          transition: all .5s;
          color: #333333;
          &:hover{
            cursor: pointer;
            color: #127BE6;
          }
        }
      }
    }
  }
  // 证书介绍
  .box3{
    margin-top: 50px;
    padding: 15px 0px;
    width: 100%;
    background-color: #FAFAFA;
    .box1_p1{
      font-size: 29px;
      font-weight: bold;
      color: #333333;
      text-align: center;
    }
    .box1_zi{
      margin-top: 30px;
      padding: 0px 15px;
      padding-bottom: 15px;
      background: #FFFFFF;
      box-shadow: 0px 0px 9px 2px rgba(185,185,185,0.27);
      .box1_zi_Desc{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
        width: 100%;
        height: 38px;
        background: #F9F9F9;
        .box1_zi_Desc_p1{
          margin-left: 20px;
          font-size: 16px;
          font-weight: bold;
          transition: all .5s;
          color: #333333;
          &:hover{
            cursor: pointer;
            color: #127BE6;
          }
        }
        .box1_zi_Desc_p2{
          margin-right: 20px;
          font-size: 16px;
          font-weight: bold;
          transition: all .5s;
          color: #333333;
          &:hover{
            cursor: pointer;
            color: #127BE6;
          }
        }
      }
    }
  }
}
</style>
