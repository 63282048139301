import { render, staticRenderFns } from "./PublicBottom.vue?vue&type=template&id=022a3774&scoped=true&"
import script from "./PublicBottom.vue?vue&type=script&lang=js&"
export * from "./PublicBottom.vue?vue&type=script&lang=js&"
import style0 from "./PublicBottom.vue?vue&type=style&index=0&id=022a3774&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "022a3774",
  null
  
)

export default component.exports