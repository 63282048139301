<!--
  作者：顾先生
-->
<template>
  <div id="ProductPage_container">
    <!--    公共头部-->
    <publicTop></publicTop>
    <!--    介绍轮播产品-->
    <div class="LunBox">
      <img :src="images" class="LunBoxImg">
      <div class="LunBox_font wapper" v-show="EVOVDVNumber === 1">
        <p class="LunBox_font_p1">EV SSL证书</p>
        <p class="LunBox_font_p2">EV SSL证书通常称为 “增强型SSL证书”，浏览器地址栏显示企业名称         </p>
        <p class="LunBox_font_p2">认证企业和域名控制权，高达256位加密算法，最高信任等级的SSL证书</p>
        <p class="LunBox_font_p2">适合: 金融机构、电子商务网站、大型企业网站等希望突显企业身份信息增强网站可信度的用户。</p>
      </div>
      <div class="LunBox_font wapper" v-show="EVOVDVNumber === 2">
        <p class="LunBox_font_p1">OV SSL证书</p>
        <p class="LunBox_font_p2">OV SSL证书 通常称为 “企业型SSL证书”，CA机构在1-3个工作日内完</p>
        <p class="LunBox_font_p2">成认证“域名使用权”和“企业身份信息”后签发证书。企业型SSL证书</p>
        <p class="LunBox_font_p2">包含申请者的公司名称，访客可通过网站上的SSL证书查看已认证的单位名称，为网站带来更高可信度。</p>
      </div>
      <div class="LunBox_font wapper" v-show="EVOVDVNumber === 3">
        <p class="LunBox_font_p1">DV SSL证书</p>
        <p class="LunBox_font_p2"> DV SSL证书 通常称为“域名型（基础型）SSL证书”，域名型SSL证书</p>
        <p class="LunBox_font_p2">具有快速签发的优势，只需完成域名控制权验证后即可签发证书，无需人验证申请</p>
        <p class="LunBox_font_p2">单位真实身份，价格低廉，通常5-30分钟即可收到证书。适用于个人用户、中小型企业等。</p>
      </div>
      <!--    证书类型按钮-->
      <div class="typeBtn">
        <div class="typeBtnBox1" @click="EV(1)">EV型SSL证书</div>
        <div class="typeBtnBox2" @click="OV(2)">OV型SSL证书</div>
        <div class="typeBtnBox3" @click="DV(3)">DV型SSL证书</div>
      </div>
    </div>
    <!--    产品价格-->
    <div class="priceBox" v-show="EVOVDVNumber === 1">
      <img src="../assets/image/EV.png" class="priceBox_img">
      <img src="../assets/image/btn.png" class="priceBox_btn1" @click="EVBtn()">
      <img src="../assets/image/btn.png" class="priceBox_btn2" @click="EVBtn()">
    </div>
    <div class="priceBox2" v-show="EVOVDVNumber === 2">
      <img src="../assets/image/OV.png" class="priceBox_img">
      <img src="../assets/image/btn.png" class="priceBox_btn1" @click="EVBtn()">
      <img src="../assets/image/btn.png" class="priceBox_btn2" @click="EVBtn()">
      <img src="../assets/image/btn.png" class="priceBox_btn3" @click="EVBtn()">
    </div>
    <div class="priceBox2" v-show="EVOVDVNumber === 3">
      <img src="../assets/image/DV.png" class="priceBox_img">
      <img src="../assets/image/btn.png" class="priceBox_btn1" @click="EVBtn()">
      <img src="../assets/image/btn.png" class="priceBox_btn2" @click="EVBtn()">
      <img src="../assets/image/btn.png" class="priceBox_btn3" @click="EVBtn()">
    </div>
    <!--    DV、OV、EV SSL证书类型对比-->
    <div class="contrastBox">
      <p class="contrastBox_p1">DV、OV、EV SSL证书类型对比</p>
      <img src="../assets/image/duibi.png" class="contrastBox_img">
    </div>
    <!--    公共底部-->
    <publicBottom></publicBottom>
  </div>
</template>

<script>
import publicTop from '@/components/PublicTop.vue'
import publicBottom from '@/components/PublicBottom.vue'
export default {
  name: 'ProductPage',
  // 注册组件
  components: { publicTop, publicBottom },
  // 注册方法
  methods: {
    // EV按钮
    EV (val) {
      this.images = require('../assets/image/2.png')
      this.EVOVDVNumber = val
    },
    // OV按钮
    OV (val) {
      this.images = require('../assets/image/3.png')
      this.EVOVDVNumber = val
    },
    // DV按钮
    DV (val) {
      this.images = require('../assets/image/4.png')
      this.EVOVDVNumber = val
    },
    EVBtn () {
      const val = 'LLB'
      this.$router.push({
        path: `/AboutPage/${val}`
      })
    }
  },
  // 父子传值
  props: {},
  // 数据渲染
  data () {
    return {
      images: require('../assets/image/2.png'),
      EVOVDVNumber: 1
    }
  },
  // 计算属性
  computed: {},
  // 监听器
  watch: {},
  // 页面初次解析完成
  created () {
  },
  // 页面内容渲染完成
  mounted () {
  }
}
</script>

<style scoped lang="less">
#ProductPage_container{
  // 轮播图
  .LunBox{
    position: relative;
    width: 100%;
    height: 350px;
    .LunBoxImg{
      position: absolute;
      width: 100%;
      height: 350px;
    }
    .LunBox_font{
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 40px;
      z-index: 9999
    }
    // 字体样式
    .LunBox_font_p1{
      margin-bottom: 20px;
      font-size: 31px;
      font-weight: bold;
      color: #FFFFFF;
      text-shadow: 0px 6px 4px rgba(0,0,0,0.27);
    }
    .LunBox_font_p2{
      text-align: justify;
      font-size: 18px;
      line-height: 32px;
      color: #FFFFFF;
      font-weight: 400;
    }
    // 证书类型按钮
    .typeBtn{
      display: flex;
      position: absolute;
      left: 50%;
      bottom: -30px;
      transform: translateX(-50%);
      width: 634px;
      height: 55px;
      .typeBtnBox1{
        flex: 1;
        height: 100%;
        background-color: #FFB53C;
        transition: all .5s;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 55px;
        &:hover{
          cursor: pointer;
          background-color: #ffdb3c;
        }
      }
      .typeBtnBox2{
        flex: 1;
        height: 100%;
        background-color: #0298D0;
        transition: all .5s;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 55px;
        &:hover{
          cursor: pointer;
          background-color: #02d0bb;
        }
      }
      .typeBtnBox3{
        flex: 1;
        height: 100%;
        background-color: #02D094;
        transition: all .5s;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 55px;
        &:hover{
          cursor: pointer;
          background-color: #77d002;
        }
      }
    }
  }
  // 产品价格
  .priceBox{
    position: relative;
    width: 850px;
    height: 215px;
    margin: 30px auto 0px;
    .priceBox_img{
      width: 100%;
      height: 100%;
    }
    .priceBox_btn1{
      width: 100px;
      height: 40px;
      position: absolute;
      right: 33px;
      top: 78px;
      transition: all .5s;
      &:hover{
        transform: scale(1.03);
        cursor: pointer;
      }
    }
    .priceBox_btn2{
      width: 100px;
      height: 40px;
      position: absolute;
      right: 33px;
      top: 145px;
      transition: all .5s;
      &:hover{
        transform: scale(1.03);
        cursor: pointer;
      }
    }
  }
  // 产品价格
  .priceBox2{
    position: relative;
    width: 850px;
    height: 281px;
    margin: 30px auto 0px;
    .priceBox_img{
      width: 100%;
      height: 100%;
    }
    .priceBox_btn1{
      width: 100px;
      height: 40px;
      position: absolute;
      right: 33px;
      top: 78px;
      transition: all .5s;
      &:hover{
        transform: scale(1.03);
        cursor: pointer;
      }
    }
    .priceBox_btn2{
      width: 100px;
      height: 40px;
      position: absolute;
      right: 33px;
      top: 145px;
      transition: all .5s;
      &:hover{
        transform: scale(1.03);
        cursor: pointer;
      }
    }
    .priceBox_btn3{
      width: 100px;
      height: 40px;
      position: absolute;
      right: 33px;
      top: 211px;
      transition: all .5s;
      &:hover{
        transform: scale(1.03);
        cursor: pointer;
      }
    }
  }
  // 产品对比
  .contrastBox{
    margin-top: 10px;
    padding: 20px 0px;
    width: 100%;
    background-color: #ECF5FF;
    .contrastBox_p1{
      text-align: center;
      font-size: 25px;
      font-weight: bold;
      color: #333333;
    }
    .contrastBox_img{
      display: block;
      margin: 0 auto;
      width: 830px;
    }
  }
}
</style>
