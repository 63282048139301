<!--
  作者：顾先生
-->
<template>
  <div id="PublicBottom_container">
    <!--    公共底部-->
    <div class="bottomBoxBox">
      <div class="bottomBox_Nei">
        <ul class="bottomBox_Nei_ul">
          <li @click="HomePage()">首页</li>
          <li @click="ProductPage()">SSL产品介绍</li>
          <li @click="TechnologyPage()">技术支持</li>
          <li @click="AboutPage()">关于我们</li>
        </ul>
        <p class="bottomBox_Nei_p1">联系电话:400-8855-561 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  邮箱:postmaster@rztrust.com</p>
        <p class="bottomBox_Nei_p1">C2016-2024 保定梵天网络科技有限公司 &nbsp;&nbsp;备案号:冀ICP备2021023123号-3 </p>
        <p class="bottomBox_Nei_p1">地址:河北省保定市莲池区东金庄乡七一东路1588号未来金融港1号楼514室</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PublicBottom',
  // 注册组件
  components: {},
  // 注册方法
  methods: {
    // 首页
    HomePage () {
      this.$router.push({
        path: '/'
      })
    },
    // SSL产品介绍
    ProductPage () {
      this.$router.push({
        path: '/ProductPage'
      })
    },
    // 技术支持
    TechnologyPage () {
      this.$router.push({
        path: '/TechnologyPage'
      })
    },
    // 关于我们
    AboutPage () {
      this.$router.push({
        path: '/AboutPage/v'
      })
    }
  },
  // 父子传值
  props: {},
  // 数据渲染
  data () {
    return {

    }
  },
  // 计算属性
  computed: {},
  // 监听器
  watch: {},
  // 页面初次解析完成
  created () {},
  // 页面内容渲染完成
  mounted () {}
}
</script>

<style scoped lang="less">
#PublicBottom_container{
  // 公共底部
  .bottomBoxBox{
    margin-top: 28px;
    margin-bottom: 20px;
    width: 100%;
    .bottomBox_Nei{
      margin: 0 auto;
      .bottomBox_Nei_ul{
        display: flex;
        justify-content: center;
        li {
          padding-right: 20px;
          padding-left: 20px;
          border-right: 1px solid #D5D5D5;
          font-size: 16px;
          font-weight: 400;
          color: #666666;
          &:hover{
            cursor: pointer;
            color: deepskyblue;
          }
        }
        li:last-child{
          border-right:none;
        }
      }
      .bottomBox_Nei_p1{
        margin-top: 20px;
        font-size: 16px;
        font-weight: 400;
        color: #666666;
        text-align: center;
      }
    }
  }
}
</style>
