import Vue from 'vue'
import VueRouter from 'vue-router'
import HomePage from '@/views/HomePage.vue'
import ProductPage from '@/views/ProductPage.vue'
import TechnologyPage from '@/views/TechnologyPage.vue'
import ContentPage from '@/components/ContentPage.vue'
import AboutPage from '@/views/AboutPage.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage
  },
  {
    path: '/ProductPage',
    name: 'ProductPage',
    component: ProductPage
  },
  {
    path: '/TechnologyPage',
    name: 'TechnologyPage',
    component: TechnologyPage
  },
  {
    path: '/ContentPage/:numNumber',
    name: 'ContentPage',
    component: ContentPage
  },
  {
    path: '/AboutPage/:NumberS',
    name: 'AboutPage',
    component: AboutPage
  }
]

const router = new VueRouter({
  routes
})

export default router
